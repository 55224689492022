import { FabricContext } from '@context/FabricContext';
import { useSelectedMVComment, useTypedSelector } from '@hooks';
import useUserPicture from '@pages/MediaViewer/AssetViewer/Canvas/hooks/useUserPicture';
import { readyArrowCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/arrowHandlers';
import { readyBrushCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/brushHandlers';
import { readyEllipseCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/ellipseHandlers';
import { readyLineCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/lineHandlers';
import { readyPinCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/pinHandlers';
import { readyRectCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/rectHandlers';
import { readyTextCreator } from '@pages/MediaViewer/AssetViewer/Canvas/Objects/textHandlers';
import changeObjectPosition from '@pages/MediaViewer/AssetViewer/Canvas/utils/changeObjectsPosition';
import { fitObjectSize } from '@pages/MediaViewer/AssetViewer/Canvas/utils/fitObject';
import { useContext, useEffect, useCallback, useState, useRef } from 'react';

export default function useCanvasHistory({
  assetType
}: {
  assetType:
    | 'audio'
    | 'video'
    | 'document'
    | 'raster_image'
    | 'camera_raw_image'
    | 'vector_image'
    | 'presentation'
    | 'spreadsheet'
    | undefined;
}) {
  const { setMyPicture } = useUserPicture();
  const turnoverDegree = useTypedSelector(
    (state) => state.mediaViewer.turnoverDegree
  );
  const zoomFactor = useTypedSelector((state) => state.mediaViewer.zoomFactor);
  const selectedCommentId = useTypedSelector(
    (state) => state.mediaViewer.selectedCommentId
  );
  const selectedComment = useSelectedMVComment();
  const selectedCommentIdRrf = useRef<string>(selectedComment?.id || '');
  selectedCommentIdRrf.current = selectedComment?.id || '';
  const {
    clearCanvas,
    canvas,
    mouseOverObjectRef,
    objectToCreateRef,
    activeObjectRef
  } = useContext(FabricContext);
  const [history, setHistory] = useState<any>([]);
  const [mods, setMods] = useState<any>([]);

  useEffect(() => {
    setHistory([]);
    setMods(0);
  }, [selectedCommentId]);

  const updateHistory = useCallback(() => {
    if (!canvas) return;

    const json = canvas.toJSON(['assetAngle', 'userEmail', 'userId']) as any;
    const history = JSON.stringify(json.objects);

    setHistory((prev: any) => {
      return [...prev, { objects: history }];
    });
    setMods(0);
  }, [canvas]);

  useEffect(() => {
    if (!canvas) return;
    canvas.on('object:history:add', () => {
      updateHistory();
    });
    canvas.on('object:history:remove', () => {
      updateHistory();
    });
    canvas.on('object:history:modified', () => {
      updateHistory();
    });

    return () => {
      canvas.off('object:history:add');
      canvas.off('object:history:remove');
      canvas.off('object:history:modified');
    };
  }, [updateHistory, canvas, objectToCreateRef]);

  const handleObjectCreators = useCallback(
    (params) => {
      if (params.obj.type === 'rect') readyRectCreator(params);
      if (params.obj.type === 'brush') readyBrushCreator(params);
      if (params.obj.type === 'arrow') readyArrowCreator(params);
      if (params.obj.type === 'line') readyLineCreator(params);
      if (params.obj.type === 'ellipse') readyEllipseCreator(params);
      if (params.obj.type === 'i-text') readyTextCreator(params);
      if (params.obj.type === 'pin') {
        const { email } = setMyPicture();
        readyPinCreator({ ...params, userEmail: email });
      }
    },
    [setMyPicture]
  );

  const handleSetAnnotations = useCallback(
    (objects: any) => {
      if (!canvas) return;
      const annotations = JSON.parse(objects);
      annotations.forEach((obj: any) => {
        let _obj = obj as any;
        if (_obj.type === 'pin') {
          _obj = {
            ..._obj,
            strokeWidth: fitObjectSize(zoomFactor, assetType)
          };
        } else if (_obj.type === 'arrow') {
          _obj = {
            ..._obj,
            objects: [
              {
                ..._obj.objects[0],
                strokeWidth: fitObjectSize(zoomFactor, assetType)
              },
              {
                ..._obj.objects[1],
                scaleX: fitObjectSize(zoomFactor, assetType),
                scaleY: fitObjectSize(zoomFactor, assetType)
              }
            ]
          };
        } else {
          _obj = {
            ..._obj,
            strokeWidth: fitObjectSize(zoomFactor, assetType)
          };
        }
        const params = {
          obj: _obj,
          canModify: true,
          canvas,
          overObject: mouseOverObjectRef,
          scale: zoomFactor,
          callback: () =>
            changeObjectPosition({
              canvas,
              angle: turnoverDegree
            })
        };
        handleObjectCreators(params);
      });
      activeObjectRef.current = undefined;
    },
    [
      canvas,
      activeObjectRef,
      handleObjectCreators,
      mouseOverObjectRef,
      turnoverDegree,
      zoomFactor
    ]
  );

  const redoHistory = useCallback(() => {
    if (!canvas) return;
    if (mods <= 0) return;

    const elem = history[history.length - 1 - mods + 1];
    clearCanvas();

    setMods((prev: number) => prev - 1);
    if (!elem) return;
    handleSetAnnotations(elem.objects);
  }, [canvas, clearCanvas, history, mods, handleSetAnnotations]);

  const undoHistory = useCallback(() => {
    if (!canvas) return;
    if (mods >= history.length) return;
    const elem = history[history.length - 1 - mods - 1];

    clearCanvas();

    setMods((prev: number) => prev + 1);
    if (!elem) return;

    handleSetAnnotations(elem.objects);
  }, [canvas, clearCanvas, handleSetAnnotations, history, mods]);

  return {
    history,
    mods,
    redoHistory,
    undoHistory
  };
}
