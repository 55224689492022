/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-new */
/* eslint-disable new-cap */
/* eslint-disable no-undef */
import { hashedColor } from '@helpers/hashedColor';
import { selectComment, setCanvasMode } from '@redux/actions/mediaViewerAction';
import { fabric } from 'fabric';

type PinCreateProps = {
  e: fabric.IEvent | undefined;
  canvas: fabric.Canvas;
  overObject: { current: boolean };
  userEmail: string;
  userName: string;
  objectToCreate: { current: fabric.Object | undefined };
  scale: number;
};

type PinPointCreatorProps = {
  canvas: fabric.Canvas;
  userEmail: string;
  userName: string;
  scale: number;
  x: number;
  y: number;
  dispatch: any;
  commentId: string;
  callback: any;
};

type ReadyPinCreatorProps = {
  canvas: fabric.Canvas;
  obj: fabric.Object;
  canModify: boolean;
  userEmail: string;
  userName: string;
  overObject: { current: boolean };
  callback: any;
  scale: number;
};

const circleOptions = {
  radius: 11
};

const pinOptions = {
  type: 'pin',
  scaleX: 1,
  scaleY: 1,
  originX: 'center',
  originY: 'bottom',
  objectCaching: false
};

export function pinCreate({
  e,
  canvas,
  userEmail,
  userName,
  overObject,
  objectToCreate,
  scale
}: PinCreateProps) {
  const pointer = e ? canvas.getPointer(e.e) : undefined;
  const color = hashedColor(userEmail, 'profileAvatar');
  const center = canvas.getCenter();
  center.left /= canvas.getZoom();
  center.top /= canvas.getZoom();

  const origX = pointer ? pointer.x : center.left;
  const origY = pointer ? pointer.y : center.top;

  const text = new fabric.Text(userName, {
    fontSize: 10,
    fontWeight: 400,
    left: origX + 11.5,
    top: origY + 12,
    stroke: 'black',
    fontFamily: 'RFDewi',
    originX: 'center',
    originY: 'center'
  });

  const circle = new fabric.Circle({
    ...circleOptions,
    left: origX,
    top: origY,
    fill: color,
    strokeWidth: 1,
    stroke: 'white'
  } as any);

  const triangle = new fabric.Triangle({
    width: 10,
    height: 6,
    angle: 180,
    fill: 'white',
    left: origX + 17,
    top: origY + 28.5
  });
  objectToCreate.current = new fabric.Group([circle, triangle, text], {
    ...pinOptions,
    left: origX,
    top: origY,
    scaleX: scale,
    scaleY: scale,
    borderColor: 'transparent'
  });
  objectToCreate.current.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false
  });
  objectToCreate.current.on('mouseup', () => {
    overObject.current = false;
  });
  objectToCreate.current.on('mouseover', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mousedown:before', () => {
    overObject.current = true;
  });
  objectToCreate.current.on('mouseout', () => {
    overObject.current = false;
  });
  canvas.add(objectToCreate.current);
  canvas.fire('object:pin:added');
}

export const pinPointCreator = ({
  canvas,
  userEmail,
  userName,
  scale,
  x,
  y,
  dispatch,
  commentId,
  callback
}: PinPointCreatorProps) => {
  const color = hashedColor(userEmail, 'profileAvatar');

  const text = new fabric.Text(userName, {
    fontSize: 10,
    fontWeight: 400,
    left: x + 11.5,
    top: y + 12,
    stroke: 'black',
    fontFamily: 'RFDewi',
    originX: 'center',
    originY: 'center'
  });

  const circle = new fabric.Circle({
    ...circleOptions,
    left: x,
    top: y,
    fill: color,
    strokeWidth: 1,
    stroke: 'white'
  });
  const group = new fabric.Group([circle, text], {
    ...pinOptions,
    left: x,
    top: y,
    scaleX: scale,
    scaleY: scale,
    borderColor: 'transparent'
  });
  group.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false
  });

  group.selectable = false;
  group.hoverCursor = 'pointer';

  group.on('mouseup', () => {
    dispatch(selectComment(commentId));
    dispatch(setCanvasMode('view'));
  });

  canvas.add(group);

  if (callback) callback();
};

export const readyPinCreator = ({
  canvas,
  obj,
  userEmail,
  userName,
  canModify,
  overObject,
  callback,
  scale
}: ReadyPinCreatorProps) => {
  const color = hashedColor(userEmail, 'profileAvatar');

  const _obj = obj as any;
  const text = new fabric.Text(userName, {
    fontSize: 10,
    fontWeight: 400,
    left: _obj.left + 11.5,
    top: _obj.top + 12,
    stroke: 'black',
    fontFamily: 'RFDewi',
    originX: 'center',
    originY: 'center'
  });

  const circle = new fabric.Circle({
    ...circleOptions,
    left: _obj.left,
    top: _obj.top,
    fill: color,
    strokeWidth: 1,
    stroke: 'white'
  } as any);

  const triangle = new fabric.Triangle({
    width: 10,
    height: 6,
    angle: 180,
    fill: 'white',
    left: _obj.left + 17,
    top: _obj.top + 28.5
  });
  const group = new fabric.Group([circle, triangle, text], {
    ...pinOptions,
    left: _obj.left,
    top: _obj.top,
    scaleX: scale,
    scaleY: scale,
    borderColor: 'transparent'
  });
  group.setControlsVisibility({
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    tl: false,
    tr: false,
    mtr: false
  });

  if (!canModify) {
    group.selectable = false;
    group.hoverCursor = 'normal';
  }

  group.on('mouseup', () => {
    overObject.current = false;
  });
  group.on('mouseover', () => {
    overObject.current = true;
  });
  group.on('mousedown:before', () => {
    overObject.current = true;
  });
  group.on('mouseout', () => {
    overObject.current = false;
  });

  canvas.add(group);
  if (callback) callback();
};
