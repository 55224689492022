import changeObjectControlPosition from '@pages/MediaViewer/AssetViewer/Canvas/utils/changeObjectControlPosition';
import { Canvas } from 'fabric/fabric-impl';

type ChangeObjectPositionProps = {
  canvas: Canvas;
  angle: number;
  disableIconRender?: boolean;
};
function getRotateObject(prefDeg: number, newDeg: number) {
  const p1 = prefDeg - newDeg;
  let p2 = 4 - p1 / 90;
  if (p2 > 4) p2 -= 4;
  return p2 === 4 ? 0 : p2 * 90;
}
export default function changeObjectPosition({
  canvas,
  angle
}: ChangeObjectPositionProps) {
  canvas.getObjects().forEach((obj: any) => {
    const _angle = getRotateObject(obj.assetAngle, angle);

    const objAngle = getRotateObject(obj.angle, _angle);

    obj.set('angle', _angle);
    const top = obj.get('top');
    const left = obj.get('left');
    let newLeft = 0;
    let newTop = 0;
    const width = canvas.getWidth() / canvas.getZoom();
    const height = canvas.getHeight() / canvas.getZoom();

    switch (objAngle) {
      case 90:
        newLeft = width - top;
        newTop = left;
        break;
      case 180:
        newLeft = width - left;
        newTop = height - top;
        break;
      case 270:
        newLeft = top;
        newTop = height - left;
        break;
      default:
        newLeft = left;
        newTop = top;
    }
    obj.set('top', newTop);
    obj.set('left', newLeft);
    changeObjectControlPosition({
      obj,
      angle
    });
    obj.setCoords();
  });
  canvas.renderAll();
}
